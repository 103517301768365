<template>
    <Layout>
        <!-- <div> -->
        <!-- <h4 class="mb-1 font-size-18">
                {{ $t(`asset logs`) }}
                <span class="ms-1 badge bg-info bg-gradient">{{
                    $t('agent commissions')
                }}</span>
            </h4> -->
        <MoneyManagementTabs />
        <!-- </div> -->
        <div class="card data">
            <div class="card-header bg-light bg-soft filters">
                    <table
                        class="table table-bordered table-nowrap mb-0 table-form">
                        <tbody>
                            <tr>
                                <td class="text-center hidden">
                                    {{ $t('date') }}
                                </td>
                                <td class="bg-white" style="width: 50%">
                                    <div class="d-flex gap-1">
                                        <datepicker
                                            v-model="filters.from"
                                            :first-day-of-week="0"
                                            :locale="$i18n.locale=='ko'?koLocale:null"
                                            confirm
                                            class="form-control bg-white"></datepicker>

                                        <label class="input-group-text"
                                            >~</label
                                        >
                                        <datepicker
                                            v-model="filters.to"
                                            :first-day-of-week="0"
                                            :locale="$i18n.locale=='ko'?koLocale:null"
                                            confirm
                                            class="form-control bg-white"></datepicker>
                                        <button
                                            class="btn btn-outline-info flex-grow-1"
                                            type="button"
                                            @click="setDay('today')">
                                            {{ $t('today') }}
                                        </button>
                                        <button
                                            class="btn btn-outline-success flex-grow-1"
                                            type="button"
                                            @click="setDay('yesterday')">
                                            {{ $t('yesterday') }}
                                        </button>
                                    </div>
                                </td>
                                <td
                                    class="text-center hidden"
                                    v-if="
                                        ['player', 'agent'].includes(
                                            this.filters.role
                                        )
                                    ">
                                    {{ $t('username') }}
                                </td>
                                <td
                                    class="bg-white"
                                    v-if="
                                        ['player', 'agent'].includes(
                                            this.filters.role
                                        )
                                    ">
                                    <input
                                        type="text"
                                        class="form-control"
                                        :placeholder="
                                            $t(`enter exact username`)
                                        "
                                        v-model="filters.username" />
                                </td>
                                <td class="text-center">
                                    {{ $t('keyword') }}
                                </td>
                                <td class="bg-white">
                                     <b-form inline @submit.prevent="initList(1)">
                                        <div class="d-flex gap-1">
                                            <input
                                                type="text"
                                                class="form-control"
                                                :placeholder="$t(`enter keyword`)"
                                                v-model="filters.keyword" />
                                            <button
                                                :class="`btn btn-info ${
                                                    loading ? 'disabled' : ''
                                                }`"
                                                type="submit">
                                                {{ $t('search') }}
                                            </button>
                                            <button
                                                :class="`btn btn-danger ${
                                                    loading ? 'disabled' : ''
                                                }`"
                                                @click="resetFilter()">
                                                {{ $t('reset') }}
                                            </button>
                                        </div>
                                    </b-form>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <LoadingTable
                        :cols="2"
                        :rows="1"
                        :hide_thead="true"
                        class="table-bordered bg-white mb-0"
                        v-if="loading" />
                    <table v-else class="table table-bordered mb-0 table-grid">
                        <tbody>
                            <tr class="bg-white">
                                <td>
                                    <div class="text-muted">
                                        {{ $t('total commissions') }}
                                    </div>
                                    <div class="text-end fw-bold">
                                        <span
                                            >{{
                                                numberStringDecimal(
                                                    total.total_commissions
                                                )
                                            }}
                                            {{ $t('KRW') }}</span
                                        >
                                        ({{ numberString(total.total_count) }})
                                    </div>
                                </td>
                                <td class="bg-light bg-soft">
                                    <multiselect
                                        style="width: 120px"
                                        class="multiselect-sm me-0"
                                        :placeholder="`${$t('limit')}`"
                                        v-model="filters.limit"
                                        :options="[50, 100, 500, 1000]"
                                        :preselect-first="true"
                                        :can-deselect="false"
                                        :can-clear="false">
                                        <template
                                            v-slot:singlelabel="{ value }">
                                            <span class="mx-auto">{{
                                                $t('view') + ' ' + value.value
                                            }}</span>
                                        </template>
                                    </multiselect>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                
            </div>
            <div class="card-body">
                <div ref="tableScroller" class="table-responsive">
                    <LoadingTable
                        :cols="8"
                        :rows="50"
                        :td_small_class="`d-none`"
                        class="table-bordered table-striped"
                        v-if="loading" />
                    <table
                        v-else
                        class="table table-bordered table-hover table-striped table-nowrap mb-0 table-list">
                        <thead class="hidden">
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">
                                    {{ $t('agent') }}
                                </th>
                                <th scope="col">
                                    {{ $t('created at') }}
                                </th>
                                <th scope="col">
                                    {{ $t('player') }}
                                </th>
                                <th scope="col">
                                    {{ $t('commission type') }}
                                </th>
                                <th scope="col">
                                    {{ $t('bet amount') }}
                                </th>
                                <th scope="col">
                                    {{ $t('commission percentage') }}
                                </th>
                                <th scope="col">
                                    {{ $t('commission amount') }}
                                </th>
                                <th scope="col">
                                    {{ $t('previous points') }}
                                </th>
                                <th scope="col">
                                    {{ $t('accumulated points') }}
                                </th>
                                <th scope="col">
                                    {{ $t('betting number') }}
                                </th>
                                <th scope="col">
                                    {{ $t('game result') }}
                                </th>
                                <!-- <th scope="col">
                                    {{ $t('note') }}
                                </th> -->
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(r, i) in data.list" :key="r._id">
                                <td class="text-center">
                                    {{
                                        i +
                                        1 +
                                        (data.current_page - 1) * data.per_page
                                    }}
                                </td>
                                <td class="text-start">
                                    <div class="td-username" v-if="r.user">
                                        <a
                                            href="javascript:void(0)"
                                            @click="
                                                goToTicketForm(
                                                    r.user.username,
                                                    r.user.role
                                                )
                                            ">
                                            <i
                                                class="bx bx-envelope bx-tada-hover fs-4"></i
                                        ></a>
                                        <a
                                            :title="$t(`click to view`)"
                                            href="javascript:void(0)"
                                            @click="
                                                goToUserForm(
                                                    r.user_id,
                                                    r.user.role
                                                )
                                            "
                                            class="link-username"
                                            ><i
                                                :class="`mdi ${
                                                    r.user.type == 'online'
                                                        ? 'mdi-web text-info'
                                                        : r.user.type ==
                                                          'offline'
                                                        ? 'mdi-storefront text-success'
                                                        : ''
                                                } fs-5`"></i>
                                            <i
                                                :class="`mdi ${
                                                    r.user.role == 'agent'
                                                        ? 'mdi-account-tie text-danger'
                                                        : r.user.role ==
                                                          'player'
                                                        ? 'mdi-account-cowboy-hat text-primary'
                                                        : ''
                                                } fs-5`"></i>
                                            <i
                                                :class="`mdi mdi-numeric-${
                                                    r.user[r.user.role].level
                                                }-box level-${
                                                    r.user[r.user.role].level
                                                } fs-5`"></i>
                                            {{ r.user.username }}</a
                                        >
                                    </div>
                                </td>
                                <td class="text-center">
                                    {{ dateYmD(r.created_at, true) }}
                                </td>
                                <td class="text-start">
                                    <a
                                        v-if="r.bet"
                                        href="javascript:void(0)"
                                        @click="
                                            goToTicketForm(
                                                r.bet.user.username,
                                                'player'
                                            )
                                        ">
                                        <i
                                            class="bx bx-envelope bx-tada-hover fs-4"></i>
                                    </a>
                                    <a
                                        v-if="r.bet"
                                        :title="$t(`click to view`)"
                                        href="javascript:void(0)"
                                        @click="
                                            goToUserForm(
                                                r.bet.user_id,
                                                'player'
                                            )
                                        ">
                                        <i
                                            :class="`mdi mdi-numeric-${0}-box level-${0} fs-5`"></i>
                                        {{ r.bet.user.username }}</a
                                    >
                                </td>
                                <td class="text-center">
                                    {{
                                        r.bet &&
                                        r.bet.agent_claim_commission_type ==
                                            'table'
                                            ? $t('casino point')
                                            : r.bet &&
                                              r.bet
                                                  .agent_claim_commission_type ==
                                                  'slots'
                                            ? $t('slot point')
                                            : !r.bet &&
                                              r.type == 'convert-commissions'
                                            ? $t('convert commission')
                                            : $t(r.type)
                                    }}
                                </td>
                                <!-- <td :class="`text-end ${r.amount<0?'text-danger':'text-info'}`"> -->
                                <td class="text-end text-primary fw-bold">
                                    {{
                                        r.bet
                                            ? numberStringDecimal(r.bet.amount)
                                            : ''
                                    }}
                                </td>
                                <td class="text-end text-danger fw-bold">
                                    {{
                                        numberStringDecimal(
                                            r.commission_percentage
                                        )
                                    }}%
                                </td>
                                <td class="text-end text-black fw-bold">
                                    {{ numberStringDecimal(r.amount) }}
                                </td>
                                <td class="text-end">
                                    {{ numberString(r.initial_value) }}
                                </td>
                                <td class="text-end">
                                    {{ numberString(r.updated_value) }}
                                </td>
                                <td class="text-center">
                                    <a
                                        href="javascript:void(0)"
                                        v-if="r.bet"
                                        @click="showViewModal(r.bet._id)">
                                        {{
                                            r.bet &&
                                            r.bet.provider == 'honorlink'
                                                ? r.bet.reference_debit
                                                      .transaction.id
                                                : r.bet &&
                                                  r.bet.provider == 'kplay'
                                                ? r.bet.reference_debit.txn_id
                                                : ''
                                        }}</a
                                    >
                                </td>
                                <td class="text-center">
                                    <div v-if="r.bet">
                                        {{
                                            r.bet.provider == 'honorlink' &&
                                            r.bet.game == 'baccarat' &&
                                            r.bet.reference_details &&
                                            r.bet.reference_details.data
                                                ? $t(
                                                      [
                                                          'Player',
                                                          'Banker',
                                                      ].includes(
                                                          r.bet
                                                              .reference_details
                                                              .external.detail
                                                              .data.result
                                                              .outcome
                                                      )
                                                          ? r.bet
                                                                .reference_details
                                                                .external.detail
                                                                .data.result
                                                                .outcome +
                                                                ' Wins'
                                                          : r.bet
                                                                .reference_details
                                                                .external.detail
                                                                .data.result
                                                                .outcome
                                                  )
                                                : null
                                        }}
                                    </div>
                                </td>
                                <!-- <td class="text-start text-muted">
                                    {{ r.note }}
                                </td> -->
                                <td class="mobile">
                                    <div class="cell-card align-items-baseline">
                                        <div>
                                            <div class="td-username">
                                                <a
                                                    v-if="r.user"
                                                    href="javascript:void(0)"
                                                    @click="
                                                        goToTicketForm(
                                                            r.user.username,
                                                            'agent'
                                                        )
                                                    ">
                                                    <i
                                                        class="bx bx-envelope bx-tada-hover fs-4"></i
                                                ></a>

                                                <a
                                                    v-if="r.user"
                                                    :title="$t(`click to view`)"
                                                    href="javascript:void(0)"
                                                    @click="
                                                        goToUserForm(
                                                            r.user_id,
                                                            'agent'
                                                        )
                                                    "
                                                    class="link-username">
                                                    <i
                                                        :class="`mdi mdi-numeric-${0}-box level-${0} fs-5`"></i>
                                                    {{ r.user.username }}</a
                                                >
                                            </div>
                                            <a
                                                href="javascript:void(0)"
                                                v-if="r.bet"
                                                @click="
                                                    showViewModal(r.bet._id)
                                                ">
                                                {{
                                                    r.bet &&
                                                    r.bet.provider ==
                                                        'honorlink'
                                                        ? r.bet.reference_debit
                                                              .transaction.id
                                                        : r.bet &&
                                                          r.bet.provider ==
                                                              'kplay'
                                                        ? r.bet.reference_debit
                                                              .txn_id
                                                        : ''
                                                }}</a
                                            >
                                            <div>
                                                {{
                                                    dateYmD(r.created_at, true)
                                                }}
                                            </div>
                                        </div>
                                        <div class="text-muted">
                                            <div
                                                class="d-flex justify-content-between">
                                                {{ $t('bet amount') }}:
                                                <span
                                                    class="text-primary fw-bold"
                                                    >{{
                                                        r.bet
                                                            ? numberStringDecimal(
                                                                  r.bet.amount
                                                              )
                                                            : ''
                                                    }}</span
                                                >
                                            </div>
                                            <div
                                                class="d-flex justify-content-between">
                                                {{
                                                    $t('commission percentage')
                                                }}:
                                                <span
                                                    class="text-danger fw-bold"
                                                    >{{
                                                        numberStringDecimal(
                                                            r.commission_percentage
                                                        )
                                                    }}%
                                                </span>
                                            </div>
                                            <div
                                                class="d-flex justify-content-between">
                                                {{ $t('commission amount') }}:
                                                <span class="text-black fw-bold"
                                                    >{{
                                                        numberString(r.amount)
                                                    }}
                                                </span>
                                            </div>
                                        </div>
                                        <div class="text-muted">
                                            <div
                                                class="d-flex justify-content-between">
                                                {{ $t('previous points') }}:
                                                <span>{{
                                                    numberString(
                                                        r.initial_value
                                                    )
                                                }}</span>
                                            </div>
                                            <div
                                                class="d-flex justify-content-between">
                                                {{ $t('accumulated points') }}:
                                                <span>{{
                                                    numberString(
                                                        r.updated_value
                                                    )
                                                }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div
                        class="no-data-placeholder"
                        v-if="!data.list.length && !loading">
                        {{ $t('no data found') }}
                    </div>
                </div>
                <Pagination v-if="!loading" :data="data" @emitPage="initList" />
            </div>
        </div>
        <b-modal
            v-model="view_modal"
            @hidden="reset()"
            centered
            :title="$t(`details`)"
            title-class="font-18"
            body-class="pt-0"
            size="xl"
            hide-footer>
            <div
                class="loader-wrapper"
                v-if="
                    view_loading ||
                    (iframe_loading && Boolean(bet_detail.kplay_bet_detail))
                ">
                <b-spinner variant="dark"></b-spinner>
                <p class="fw-bold fs-5">{{ $t('loading') }}...</p>
            </div>
            <div class="bet-details-header">
                <div :class="`flex-fill`">
                    <table class="table table-sm table-bordered mb-0">
                        <tbody>
                            <tr>
                                <th class="bg-light bg-soft" style="width: 15%">
                                    {{ $t('date') }}
                                </th>
                                <td style="width: 35%">
                                    {{ dateYmD(bet_detail.created_at, true) }}
                                </td>
                                <th class="bg-light bg-soft" style="width: 15%">
                                    {{ $t('status') }}
                                </th>
                                <td style="width: 35%">
                                    <span
                                        :class="{
                                            'text-primary':
                                                bet_detail.status == 'pending',
                                            'text-danger':
                                                bet_detail.status == 'win',
                                            'text-info':
                                                bet_detail.status == 'lose',
                                            'text-secondary':
                                                bet_detail.status == 'cancel',
                                        }"
                                        >{{ $t(bet_detail.status) }}</span
                                    >
                                </td>
                            </tr>
                            <tr>
                                <th class="bg-light bg-soft" style="width: 15%">
                                    {{ $t('username') }}
                                </th>
                                <td style="width: 35%">
                                    {{ bet_detail.username }}
                                </td>
                                <th class="bg-light bg-soft" style="width: 15%">
                                    {{ $t('amount') }}
                                </th>
                                <td style="width: 35%">
                                    {{ numberString(bet_detail.amount) }}
                                </td>
                            </tr>
                            <tr>
                                <th class="bg-light bg-soft" style="width: 15%">
                                    {{ $t('game') }}
                                </th>
                                <td style="width: 35%">
                                    {{
                                        bet_detail.game
                                            ? $t(bet_detail.game)
                                            : null
                                    }}
                                </td>
                                <th class="bg-light bg-soft" style="width: 15%">
                                    {{ $t('payment amount') }}
                                </th>
                                <td style="width: 35%">
                                    {{ numberString(bet_detail.win_amount) }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="baccarat-cards" v-if="bet_detail.card_info">
                    <span
                        :class="`tie-indicator ${
                            bet_detail.card_info.outcome == 'Tie'
                                ? 'result'
                                : ''
                        }`"
                        >{{ $t('Tie') }}</span
                    >
                    <div
                        :class="`text-start d-flex align-items-center gap-1 player ${
                            bet_detail.card_info.outcome == 'Player'
                                ? 'result'
                                : ''
                        }`">
                        <div class="side">
                            {{ $t('player') }}
                        </div>
                        <div class="cards">
                            <img
                                v-for="(c, i) in bet_detail.card_info.player
                                    .cards"
                                :key="i"
                                :src="require(`@/assets/images/cards/${c}.png`)"
                                :alt="`${c}.png`" />
                        </div>
                        <div class="score">
                            {{ bet_detail.card_info.player.score }}
                        </div>
                    </div>
                    <div
                        :class="`text-start d-flex align-items-center gap-1 banker ${
                            bet_detail.card_info.outcome == 'Banker'
                                ? 'result'
                                : ''
                        }`">
                        <div class="score">
                            {{ bet_detail.card_info.banker.score }}
                        </div>
                        <div class="cards">
                            <img
                                v-for="(c, i) in bet_detail.card_info.banker
                                    .cards"
                                :key="i"
                                :src="require(`@/assets/images/cards/${c}.png`)"
                                :alt="`${c}.png`" />
                        </div>
                        <div class="side">
                            {{ $t('banker') }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="table-responsive">
                <table
                    class="table table-bordered table-striped table-nowrap table-sm mb-0">
                    <thead v-show="bet_detail.agent_commissions.length">
                        <tr class="bg-light bg-soft fs-5">
                            <th colspan="6" class="text-start">
                                {{ $t('agent commission') }}
                            </th>
                        </tr>
                        <tr>
                            <th>
                                {{ $t('username') }}
                            </th>
                            <th>
                                {{ $t('percentage') }}
                            </th>
                            <th>
                                {{ $t('initial value') }}
                            </th>
                            <th>
                                {{ $t('amount') }}
                            </th>
                            <th>
                                {{ $t('updated value') }}
                            </th>
                            <th>
                                {{ $t('note') }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="p in bet_detail.agent_commissions"
                            :key="p._id">
                            <td>
                                {{ p.user ? p.user.username : '' }}
                            </td>
                            <td class="text-end text-success">
                                {{
                                    numberStringDecimal(
                                        p.commission_percentage
                                    )
                                }}%
                            </td>
                            <td class="text-end">
                                {{ numberStringDecimal(p.initial_value) }}
                            </td>
                            <td class="text-end fw-medium">
                                {{ numberStringDecimal(p.amount) }}
                            </td>
                            <td class="text-end">
                                {{ numberStringDecimal(p.updated_value) }}
                            </td>
                            <td class="text-start text-muted">
                                {{ p.note }}
                            </td>
                        </tr>
                    </tbody>
                    <thead v-show="bet_detail.player_commissions.length">
                        <tr class="bg-light bg-soft fs-5">
                            <th colspan="6" class="text-start">
                                {{ $t('player commission') }}
                            </th>
                        </tr>
                        <tr>
                            <th>
                                {{ $t('username') }}
                            </th>
                            <th>
                                {{ $t('percentage') }}
                            </th>
                            <th>
                                {{ $t('initial value') }}
                            </th>
                            <th>
                                {{ $t('amount') }}
                            </th>
                            <th>
                                {{ $t('updated value') }}
                            </th>
                            <th>
                                {{ $t('note') }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="p in bet_detail.player_commissions"
                            :key="p._id">
                            <td>
                                {{ p.user ? p.user.username : '' }}
                            </td>
                            <td class="text-end text-success">
                                {{
                                    numberStringDecimal(
                                        p.commission_percentage
                                    )
                                }}%
                            </td>
                            <td class="text-end">
                                {{ numberStringDecimal(p.initial_value) }}
                            </td>
                            <td class="text-end fw-medium">
                                {{ numberStringDecimal(p.amount) }}
                            </td>
                            <td class="text-end">
                                {{ numberStringDecimal(p.updated_value) }}
                            </td>
                            <td class="text-start text-muted">
                                {{ p.note }}
                            </td>
                        </tr>
                    </tbody>
                    <thead v-show="bet_detail.player_rebate.length">
                        <tr class="bg-light bg-soft fs-5">
                            <th colspan="6" class="text-start">
                                {{ $t('player rebate') }}
                            </th>
                        </tr>
                        <tr>
                            <th>
                                {{ $t('username') }}
                            </th>
                            <th>
                                {{ $t('percentage') }}
                            </th>
                            <th>
                                {{ $t('initial value') }}
                            </th>
                            <th>
                                {{ $t('amount') }}
                            </th>
                            <th>
                                {{ $t('updated value') }}
                            </th>
                            <th>
                                {{ $t('note') }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="p in bet_detail.player_rebate" :key="p._id">
                            <td>
                                {{ p.user ? p.user.username : '' }}
                            </td>
                            <td class="text-end text-success">
                                {{
                                    numberStringDecimal(
                                        p.commission_percentage
                                    )
                                }}%
                            </td>
                            <td class="text-end">
                                {{ numberStringDecimal(p.initial_value) }}
                            </td>
                            <td class="text-end fw-medium">
                                {{ numberStringDecimal(p.amount) }}
                            </td>
                            <td class="text-end">
                                {{ numberStringDecimal(p.updated_value) }}
                            </td>
                            <td class="text-start text-muted">
                                {{ p.note }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <iframe
                @load="iframe_loading = false"
                v-if="bet_detail.kplay_bet_detail"
                class="kplay-bet-detail"
                :src="bet_detail.kplay_bet_detail"
                frameborder="0"></iframe>
        </b-modal>
    </Layout>
</template>

<script>
import Datepicker from 'vue3-datepicker';
import Layout from '../../layouts/main';
import PageHeader from '@/components/page-header';
import LoadingTable from '@/components/loading-table';
import Pagination from '../../../components/pagination.vue';
import Multiselect from '@vueform/multiselect';
import Switches from 'vue-switches';
import MoneyManagementTabs from '@/components/money-management-tabs';
import { mapActions, mapGetters } from 'vuex';
import random from '../../../mixins/random';
import datetime from '../../../mixins/datetime';
import number from '../../../mixins/number';
import ui from '../../../mixins/ui';
export default {
    components: {
        Layout,
        PageHeader,
        Pagination,
        Switches,
        LoadingTable,
        Multiselect,
        Datepicker,
        MoneyManagementTabs,
    },
    mixins: [datetime, number, random, ui],
    data() {
        return {
            test: null,
            title: `players`,
            filters: {
                limit: 50,
                // from:
                //     this.$route.params.range == 'week'
                //         ? new Date(this.oneWeekAgo())
                //         : new Date(this.firstDateOftheMonth()),
                from:
                    this.$route.params.range == 'day'
                        ? new Date()
                        : new Date(this.oneWeekAgo()),
                to: new Date(),
                search: null,
                username: null,
                column: this.$route.params.column,
                role: this.$route.params.role,
            },
            column_options: [
                { name: this.$t('points'), value: 'points' },
                { name: this.$t('balance'), value: 'balance' },
                { name: this.$t('commissions'), value: 'commissions' },
            ],
            role_options: [
                { name: this.$t('player'), value: 'player' },
                { name: this.$t('agent'), value: 'agent' },
            ],
            data: {
                list: [],
                links: [],
                current_page: 1,
                per_page: 1,
                last_page: 1,
                total: 0,
            },
            loading: false,
            total_loading: false,
            total: {
                total_commissions: 0,
                total_count: 0,
            },
            bet_detail: {
                _id: '',
                created_at: '',
                username: '',
                game: '',
                status: '',
                amount: '',
                win_amount: '',
                player_commissions: [],
                agent_commissions: [],
                player_rebate: [],
                card_info: null,
                kplay_bet_detail: null,
            },
            view_loading: false,
            view_modal: false,
            iframe_loading: false,
        };
    },
    watch: {
        '$store.state.key'() {
            this.initList(1);
        },
    },
    methods: {
        ...mapActions('log', {
            assetGetList: 'getList',
            assetGetAgentCommissionList: 'getAgentCommissionList',
            assetGetAgentCommissionListTotal: 'getAgentCommissionListTotal',
        }),
        ...mapActions('bet', {
            betView: 'view',
        }),
        async initList(p) {
            this.resetScroll();
            var pl = {
                limit: this.filters.limit,
                page_no: p ? p : 1,
                from: this.dateYmD(this.filters.from) + ' 00:00:00',
                to: this.dateYmD(this.filters.to) + ' 23:59:59',
                search: this.filters.keyword ? this.filters.keyword : '',
            };
            this.loading = true;
            const data = await this.assetGetAgentCommissionList(pl);
            this.data.list = data.data;
            this.data.links = data.links;
            this.data.current_page = data.current_page;
            this.data.per_page = data.per_page;
            this.data.last_page = data.last_page;
            this.data.total = data.total;

            await this.initListTotal()
            this.loading = false;
        },
        async initListTotal() {
            var pl = {
                from: this.dateYmD(this.filters.from) + ' 00:00:00',
                to: this.dateYmD(this.filters.to) + ' 23:59:59',
                search: this.filters.search,
            };
            this.total_loading = true;
            const data = await this.assetGetAgentCommissionListTotal(pl);
            if (data) {
                this.total.total_commissions = data.total_commissions;
                this.total.total_count = data.total_count;
            }
            this.total_loading = false;
        },
        resetFilter() {
            this.filters.column = null;
            // this.filters.role = null;
            this.filters.username = null;
            this.filters.search = null;
			this.initList(1);
        },
        async showViewModal(id) {
            if (this.view_loading) return;
            this.view_modal = true;
            this.view_loading = true;
            const res = await this.betView(id);
            if (res) {
                this.bet_detail._id = res._id;
                this.bet_detail.created_at = res.created_at;
                this.bet_detail.username = res.user.username;
                this.bet_detail.game = res.game;
                this.bet_detail.status = res.status;
                this.bet_detail.amount = res.amount;
                this.bet_detail.win_amount = res.win_amount;
                this.bet_detail.agent_commissions = res.agent_commissions;
                this.bet_detail.player_commissions = res.player_commissions;
                this.bet_detail.player_rebate = res.player_rebate;
                this.bet_detail.card_info = res.card_info;
                this.bet_detail.kplay_bet_detail = res.kplay_bet_detail;
                if (res.kplay_bet_detail) this.iframe_loading = true;
            }
            this.view_loading = false;
        },
        reset() {
            this.bet_detail._id = '';
            this.bet_detail.created_at = '';
            this.bet_detail.username = '';
            this.bet_detail.game = '';
            this.bet_detail.status = '';
            this.bet_detail.amount = '';
            this.bet_detail.win_amount = '';
            this.bet_detail.agent_commissions = [];
            this.bet_detail.player_commissions = [];
            this.bet_detail.player_rebate = [];
            this.bet_detail.card_info = null;
            this.bet_detail.kplay_bet_detail = null;
        },
        setDay(mode) {
            if (mode == 'today') {
                this.filters.from = new Date();
                this.filters.to = new Date();
            } else {
                this.filters.from = this.addDaysToDate(-1);
                this.filters.to = this.addDaysToDate(-1);
            }
            this.initList(1);
        },
    },
    mounted() {
        this.initList(1);
        this.$watch(
            () => this.$route.params.range,
            (n, o) => {
                if (this.$route.name == 'agent-commisions')
                    this.filters.from =
                        n == 'day'
                            ? new Date()
                            : new Date(this.oneWeekAgo());
                this.initList(1);
            }
        );
    },
};
</script>
